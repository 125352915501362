import React from "react"

import { Link } from "components/anti/link/link"
import { Button } from "components/anti/buttons/buttons"
import { Card } from "components/anti/card/card"

import BGSustain1 from "assets/img/bg_about_sustain_1.png"
import BGSustain2 from "assets/img/bg_about_sustain_2.png"
import BGSustain3 from "assets/img/bg_about_sustain_3.png"

import { useScrollAnim } from "src/components/hooks/hooks"


export function AboutSustainability({ data }) {
  const [trigger, anim] = useScrollAnim()

  return (
    <section className="pt-main about-sustainability" ref={trigger}>
      <div className="container">
        <h2 className={anim(1)}>{data?.sustainability?.title}</h2>
        <p className={`${anim(2)}`}>{data?.sustainability?.text}</p>
        <div className="d-flex align-items-center">
          <Button variant="link" link={data?.sustainability?.button?.url} className={`${anim(3)}`}>
            {data?.sustainability?.button?.text}
          </Button>
        </div>
      </div>
      <div className="pt-4 sustainability-container">
        <div className="row">
          {data?.sustainability?.list?.map((item, i) => {
            return (
              <div className={`col-lg-4 px-0 ${anim(i + 4)}`}>
                <Card
                  variant="overlay"
                  img={item?.image?.sourceUrl}
                  ratio="r-4-3"
                  title={item?.title}
                  text={item?.text}
                  imgAlt={item?.image?.altText ? item?.image?.altText : "Image"}
                  key={i}
                />
              </div>
            )
          })}
        </div>
        <div className="sustainability-footer">
          <img className="bg-image curve-3" src={BGSustain1} alt="bg-image" />
          <img className="bg-image curve-2" src={BGSustain2} alt="bg-image" />
          <img className="bg-image curve-1" src={BGSustain3} alt="bg-image" />
          <div className="container py-5">
            <h2 className={`text-white mb-3 ${anim(5)}`}>{data?.marketingGallery?.title}</h2>
            <p className={`text-muted mw-md-400px ${anim(6)}`}>
              {data?.marketingGallery?.text}
            </p>
            <div className={`d-flex text-white align-items-center ${anim(7)}`}>
              <Button link={data?.marketingGallery?.button?.url} variant="link" className="text-white">
                {data?.marketingGallery?.button?.text}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
