import React from "react"

import { useScrollAnim } from "src/components/hooks/hooks"

export function AboutInfrastructure({ data }) {
  const [trigger, anim] = useScrollAnim()

  return (
    <section className="py-5 bg-light" ref={trigger}>
      <div className="container">
        <h2 className={`${anim(1)}`}>{data?.title}</h2>
        <p className={`font-weight-light text-muted ${anim(2)}`}>
          {data?.text}
        </p>
        <div className="row mt-md-5">
          {data?.list?.map((item, i) => {
            return (
              <div key={i} className={`col-md-6 d-flex ${anim(i + 1)}`}>
                <img src={item?.icon?.sourceUrl} className="h-50px" />
                <div className="mw-md-400px ml-4">
                  <h5>{item?.title}</h5>
                  <p className="font-weight-light text-gray-400">
                    {item?.text}
                  </p>
                </div>
              </div>
            )
          })}
        </div>

      </div>
    </section>
  )
}
