import React, { useState } from "react"

import { useScrollAnim } from "src/components/hooks/hooks"
import { YoutubeFrame } from "components/global/youtube-frame/youtube-frame"

import BGAch1 from "assets/img/bg_about_achievement_1.png"
import BGAch2 from "assets/img/bg_about_achievement_2.png"
import BGAch3 from "assets/img/bg_about_achievement_3.png"
import BGAchMb1 from "assets/img/bg_about_achievement_mb1.png"
import BGAchMb2 from "assets/img/bg_about_achievement_mb2.png"
import BGAchMb3 from "assets/img/bg_about_achievement_mb3.png"

export function AboutAchievement({ data }) {
  const [trigger, anim] = useScrollAnim()

  return (
    <section id="awards" className="pt-5 about-achievement" ref={trigger}>
      <YoutubeFrame link={data?.videoSrc} thumbnail={data?.videoThumbnail} className={`px-sm-down-0`} />
      <div className="achievement-container">
        <img className="img-bg-curve-video curve-1" src={BGAch1} alt="curve-1" />
        <img className="img-bg-curve-video curve-2" src={BGAch2} alt="curve-2" />
        <img className="img-bg-curve-video curve-3" src={BGAch3} alt="curve-3" />
        <img className="img-bg-curve-video curve-mb1" src={BGAchMb1} alt="curve-1 mobile" />
        <img className="img-bg-curve-video curve-mb2" src={BGAchMb2} alt="curve-2 mobile" />
        <img className="img-bg-curve-video curve-mb3" src={BGAchMb3} alt="curve-3 mobile" />
        <div className="container mw-xl py-5">
          <div className="row">
            {data?.statistics?.map((item, i) => {
              return (
                <div className={`col-md-2 col-4 mb-4 mb-md-0 ${anim(1 + i)}`} key={i}>
                  <h2 className="text-white">{item?.text}</h2>
                  <small className="text-muted">{item?.label}</small>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}
