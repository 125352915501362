import React from 'react'

import { SliderDuti } from "components/anti/slider/slider-duti"
import { useScrollAnim } from "src/components/hooks/hooks"

export function AboutLocation({ data }) {
  const [trigger, anim] = useScrollAnim()

  return (
    <section className="slider-container" ref={trigger}>
      <div className={`container mw-xl py-main py-sm-down-0 px-sm-down-0 ${anim(1)}`}>
        <SliderDuti
          values={data?.gallery?.image}
        />
      </div>
    </section>
  )
}
