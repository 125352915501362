import React, { useState, useEffect, useRef } from "react"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import { useScrollAnim } from "src/components/hooks/hooks"

import { gsap } from "gsap"

gsap.registerPlugin(ScrollTrigger)
export const AboutOverview = ({ data }) => {
  const [trigger, anim] = useScrollAnim()

  const txtRunning = useRef(null)
  const [tl, setTl] = useState(
    gsap.timeline({
      paused: true,
    })
  )

  useEffect(() => {
    tl.from(txtRunning.current, {
      x: "25%",
      scrollTrigger: {
        trigger: txtRunning.current,
        start: "top 70%",
        end: "bottom",
        // markers: true,
        scrub: 1.5,
        toggleActions: "play none none reverse",
      },
    })
  }, [])

  return (
    <section className="py-main position-relative" ref={trigger}>
      <span ref={txtRunning} className="text-running text-gray-50">
        realestate & develop
      </span>
      <div className="container mw-lg">
        <div className="w-md-75">
          <p className={`w-100 text-gray-600 font-weight-light ${anim(1)}`}>
            {data?.headline?.text}
          </p>
          <h2 className={`w-250px mt-5 ${anim(2)}`}>{data?.mission?.title}</h2>
          <p className={`w-100 text-gray-600 font-weight-light ${anim(3)}`}>
            {data?.mission?.text}
          </p>
        </div>
      </div>
    </section>
  )
}
