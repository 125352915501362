import React, { useState, useEffect, useRef } from "react"

import { Button } from "components/anti/buttons/buttons"
import { Card } from "components/anti/card/card"
import { Slider } from "./slider"

export const SliderDuti = ({ values, className = 'gallery' }) => {
  const [state, setState] = useState({
    nav1: null,
    nav2: null,
  })
  const [sliderIndex, setSliderIndex] = useState(0)
  const slider1 = useRef()
  const slider2 = useRef()

  useEffect(() => {
    setState({
      nav1: slider1.current,
      nav2: slider2.current,
    })
  }, [])

  const { nav1, nav2 } = state

  return (
    <div className={className}>
      <Slider
        asNavFor={nav2}
        forwardRef={slider => (slider1.current = slider)}
        noGutter
        visibleInitial={false}
        visibleXxlDown={false}
        visibleXlDown={false}
        visibleLgDown={false}
        visibleMdDown={false}
        visibleSmDown={false}
        arrowsMdDown={true}
        arrowsSmDown={true}
        showInitial={1}
        showXxlDown={1}
        showXlDown={1}
        showLgDown={1}
        showMdDown={1}
        showSmDown={1}
        beforeChange={val => setSliderIndex(val)}
      >
        {values?.map((value, i) => {
          return (
            <Card
              key={i}
              variant="overlay"
              img={value.sourceUrl}
              imgAlt={value.altText ? value.altText : "Image"}
              title={value.title}
              text={value.text}
              imgRatio="r-4-3 r-md-16-9"
              textClassName="text-gray-400"
            />
          )
        })}
      </Slider>
      <div class="progress progress-container bg-green">
        <div
          class="progress-bar bg-danger"
          role="progressbar"
          style={{
            width: `${((sliderIndex + 1) / values?.length) * 100}%`,
          }}
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      {values?.length && values[0]?.caption &&
        <Slider
          asNavFor={nav1}
          forwardRef={slider => (slider2.current = slider)}
          noGutter
          visibleInitial={false}
          visibleLgDown={false}
          visibleMdDown={false}
          visibleSmDown={false}
          arrowsMdDown={false}
          arrowsSmDown={false}
          showInitial={1}
          showLgDown={1}
          showMdDown={1}
          showSmDown={1}
        >
          {values.map((value, i) => {
            return (
              <div className="py-5" key={i}>
                <div className="container">
                  <p className="text-muted">{value.caption}</p>
                </div>
              </div>
            )
          })}
        </Slider>
      }
    </div>
  )
}
