import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "components/layout"

import { GlobalCover } from "components/global/cover/cover"
// import { AboutCover } from "components/pages/about/about-cover"
import { AboutLocation } from "components/pages/about/about-location"
import { AboutOverview } from "components/pages/about/about-overview"
import { AboutAchievement } from "components/pages/about/about-achievement"
// import { AboutSubscription } from "components/pages/about/about-subscription"
import { AboutInfrastructure } from "components/pages/about/about-infrastructure"
import { AboutSustainability } from "components/pages/about/about-sustainability"
import { ProjectDetailsLeadForm } from "components/pages/development/project/details/lead-form"
import { Map } from "components/anti/map/map"
import SEO from "components/seo"

const AboutPage = ({ path, location: Location, pageContext }) => {
  const data = useStaticQuery(GET_ABOUT_PAGE)
  const lang = pageContext.langKey

  const pageData = lang === "en"
    ? data?.wordPress?.page?.aboutPage
    : data?.wordPress?.page?.translation?.aboutPage
    || data?.wordPress?.page?.aboutPage

  const {
    cover: coverData,
    video: videoSrc,
    videoThumbnail,
    location: map,
    gallery,
    mission,
    headline,
    statistics,
    sustainability,
    location,
    infrastructure,
    marketingGallery,
  } = pageData

  const seo = lang === "en"
    ? data?.wordPress?.page?.seo
    : data?.wordPress?.page?.translation?.seo
    || data?.wordPress?.page?.seo

  const bgColor = lang === "en"
    ? data?.wordPress?.page?.backgroundColor?.backgroundColor?.color
    : data?.wordPress?.page?.translation?.backgroundColor?.backgroundColor?.color
    || data?.wordPress?.page?.backgroundColor?.backgroundColor?.color

  const SEODefaultImg = coverData?.image?.sourceUrl

  return (
    <Layout theme={bgColor || "light"} lang={pageContext.langKey} path={path}>
      <SEO
        title={seo?.title}
        desc={seo?.metaDesc}
        fbImg={seo?.opengraphImage?.sourceUrl || SEODefaultImg}
        fbTitle={seo?.opengraphTitle}
        fbDesc={seo?.opengraphDescription}
        url={location?.href}
        twitterTitle={seo?.twitterTitle}
        twitterDesc={seo?.twitterDescription}
        twitterImg={seo?.twitterImage?.sourceUrl || SEODefaultImg}
      />
      <GlobalCover data={coverData} theme={bgColor || "light"} breadcrumb={Location?.pathname} />
      {/* <AboutCover data={{ coverData, location: pathname }} /> */}
      <AboutOverview data={{ headline, mission }} />
      <AboutAchievement data={{ videoSrc, statistics, videoThumbnail }} />
      <Map className="w-100 h-500px" lat={location?.latitude} long={location?.longitude} />
      <AboutLocation data={{ map, gallery }} />
      <AboutInfrastructure data={infrastructure} />
      <AboutSustainability data={{ sustainability, marketingGallery }} />
      <ProjectDetailsLeadForm lang={lang} />
    </Layout>
  )
}

export default AboutPage

const GET_ABOUT_PAGE = graphql`
query About {
  wordPress {
    page(id: "about", idType: URI) {
      backgroundColor {
        backgroundColor {
          color
        }
      }
      seo {
        title
        metaDesc
        twitterTitle
        twitterDescription
        twitterImage {
          sourceUrl
          altText
        }
        opengraphTitle
        opengraphDescription
        opengraphImage {
          sourceUrl
          altText
        }
      }
      aboutPage {
        cover {
          title
          image {
            sourceUrl
            altText
          }
        }
        headline {
          text
        }
        mission {
          title
          text
        }
        video
        videoThumbnail {
          image {
            sourceUrl
            altText
            altText
          }
        }
        statistics {
          text
          label
        }
        location {
          latitude
          longitude
        }
        gallery {
          image {
            sourceUrl
            altText
            title
          }
        }
        infrastructure {
          title
          text
          list {
            icon {
              sourceUrl
              altText
            }
            title
            text
          }
        }
        sustainability {
          title
          text
          button {
            text
            url
          }
          list {
            title
            text
            image {
              sourceUrl
              altText
            }
          }
        }
        marketingGallery {
          title
          text
          button {
            text
            url
          }
        }
      }
      translation(language: ID) {
        backgroundColor {
          backgroundColor {
            color
          }
        }
        seo {
          title
          metaDesc
          twitterTitle
          twitterDescription
          twitterImage {
            sourceUrl
            altText
          }
          opengraphTitle
          opengraphDescription
          opengraphImage {
            sourceUrl
            altText
          }
        }
        aboutPage {
          cover {
            title
            image {
              sourceUrl
              altText
            }
          }
          headline {
            text
          }
          mission {
            title
            text
          }
          video
          videoThumbnail {
            image {
              sourceUrl
              altText
              altText
            }
          }
          statistics {
            text
            label
          }
          location {
            latitude
            longitude
          }
          gallery {
            image {
              sourceUrl
              altText
            }
          }
          infrastructure {
            title
            text
            list {
              icon {
                sourceUrl
                altText
              }
              title
              text
            }
          }
          sustainability {
            title
            text
            button {
              text
              url
            }
            list {
              title
              text
              image {
                sourceUrl
                altText
              }
            }
          }
          marketingGallery {
            title
            text
            button {
              text
              url
            }
          }
        }
      }
    }
  }
}
`